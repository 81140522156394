import React from 'react'

import drainlaying from '../../img/services/drainlaying_2.jpg'
import drainlayingThumb from '../../img/services/drainlaying_2_thumb.jpg'
import Layout from '../../components/layout'

const Drainlaying = () => (
  <Layout>
    <div className="box">
      <h2 className="stripe">Drainlaying Services</h2>

      <div className="serviceImage">
        <a className="fancybox" href={drainlaying} title="Complete Sewer Replacement" rel="fancybox">
          <img src={drainlayingThumb} width="250"/>
        </a>
        <p>Complete Sewer Replacement</p>
      </div>

      <p>
        Our drainlaying department is completely run by a certified drainlayer who has been trained in Australia under
        their higher drainage standards (AS/3500). All work carried out on your next renovation or new home by us, will
        be
        using these higher standards. Therefore, this reduces the risks of blockage and will withstand Canterbury’s
        unstable ground.
      </p>

      <div id="tvservicesListOne" style={{ float: 'left' }}><h3>Drainage services we offer include:</h3>
        <ul className="servicesList">
          <li>Pre-slab drainage.</li>
          <li>All renovations big and small.</li>
          <li>Stack work for multi story buildings.</li>
          <li>Storm water new homes and existing.</li>
          <li>Unblocking drains (particular drainage machine used).</li>
          <li>Repairs to damaged pipework.</li>
          <li>Soak pits.</li>
          <li>Septic tanks (including pumps).</li>
        </ul>
      </div>

      <div id="tvservicesListTwo" style={{ float: 'left', marginLeft: '20px' }}/>

      <br style={{ clear: 'left' }}/>

      <div id="tvservicesBottomText" style={{ float: 'left', width: '600px' }}>
        <p>
          As always we offer free quotes with no obligations letting the customer decide. We can offer friendly, simple
          advice for free (you might not even need us at all). All work is guaranteed and licensed to give you piece of
          mind the job is done right.
        </p>
      </div>

      <br className="clear"/>
    </div>
  </Layout>
)

export default Drainlaying
